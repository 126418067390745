.drag-sample-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.schedule-container {
  padding-right: 10px;
  width: 100%;
  overflow: visible;
}

.e-schedule {
  overflow: visible;
}

.title-container {
  padding-bottom: 10px;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .template-wrap {
  padding: 3px 0px;
}

.title-text {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  text-align: center;
}

.treeview-external-drag {
  width: 325px;
}

.treeview-external-drag #waitlist {
  width: 100%;
  height: 50%;
  font-weight: bold;
  font-family: "Segoe UI";
  font-size: 12px;
  padding: 5px 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
  width: 160px;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-category {
  padding-left: 30px;
}

.e-schedule.e-rtl.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-category {
  padding-right: 30px;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-category,
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
  padding: 5px;
}

.tailwind
  .e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-category,
.tailwind
  .e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-name,
.tailwind-dark
  .e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-category,
.tailwind-dark
  .e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-name {
  padding: unset !important;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-name {
  padding: 0 10px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-name {
  font-size: 13px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-designation {
  font-size: 10px;
}

.e-schedule.schedule-drag-drop .specialist-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 45px;
}

.e-device-hover {
  background-color: #e0e0e0 !important;
}

.e-work-cells.e-drop-allowed {
  background-color: rgb(189, 255, 189) !important;
}

.e-work-cells.e-drop-not-allowed {
  background-color: rgb(253, 153, 153) !important;
}

.e-work-cells.e-drop-would-conflict {
  background-color: rgb(253, 153, 153) !important;
}

.e-work-cells.e-unavailable {
  background-color: darkgray !important;
}

.e-work-cells.e-license-expired {
  background-color: rgb(253, 153, 153) !important;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-image {
  display: none;
}

@media (max-width: 550px) {
  .drag-sample-wrapper {
    display: block;
  }
  .schedule-container {
    padding-bottom: 10px;
  }
  .treeview-external-drag.e-treeview,
  .e-bigger .treeview-external-drag.e-treeview {
    width: 250px;
  }
  .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
    position: relative !important;
  }
}
.e-disble-not-allowed {
  cursor: unset !important;
}

.e-drag-item.treeview-external-drag .e-icon-expandable {
  display: none;
}

.e-dlg-container {
  z-index: 1200 !important;
}

/* Hide edit dialog buttons, we have our own */
.e-schedule-dialog .e-dlg-header-content .e-dlg-header,
.e-schedule-dialog .e-footer-content {
  display: none;
}

.e-dialog {
  width: 1000px !important;
}

.e-cell-popup {
  display: none;
}

.e-schedule .e-timeline-view .e-date-header-wrap table col,
.e-schedule .e-timeline-view .e-content-wrap table col,
.e-schedule .e-timeline-month-view .e-date-header-wrap table col,
.e-schedule .e-timeline-month-view .e-content-wrap table col {
  width: 20px;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td {
  width: 180px;
}
.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 0;
}

/* etaDiv.style.position = "absolute";
etaDiv.style.bottom = "0";
etaDiv.style.right = "0";
etaDiv.style.backgroundColor = theme.palette.primary.main;
etaDiv.style.color = theme.palette.primary.contrastText;
etaDiv.style.transform = `translateX(100%)`; */

.e-appointment {
  overflow: visible !important;
}

.eta-to-next-trip,
.eta-from-previous-trip {
  position: absolute;
  top: 0;
  bottom: 0;
  word-break: break-all;
  color: #fff;
  padding: 10px 3px;
  overflow-wrap: break-word;
  overflow: hidden;
}

.eta-to-next-trip {
  right: 0;
  transform: translateX(100%);
  background-color: #3f51b5;
  border-radius: 0 5px 5px 0;
}

.eta-from-previous-trip {
  display: none;
  left: 0;
  transform: translateX(-100%);
  background-color: #6c3fb5;
  border-radius: 5px 0 0 5px;
}

.e-appointment-container .e-appointment:first-of-type .eta-from-previous-trip {
  display: block;
}

.e-appointment-container .e-appointment:last-of-type .eta-to-next-trip {
  background-color: #6c3fb5;
}

.e-appointment.e-combinable {
  box-shadow: 10px 10px 30px #fff;
  background-color: rgb(37, 204, 37) !important;
}

.e-schedule .e-resource-cells {
  display: block;
  padding-left: 5px !important;
}
